import React, { useEffect, useState } from "react";
import Logo from "../assets/images/Logo.png";
import { BsMoonStarsFill } from "react-icons/bs";
import { BsFillSunFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { changeColorTheme } from "../redux/reducers/colorThemeSlicer";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";

import { motion } from "framer-motion";

function Navbar() {
  const dispatch = useDispatch();
  const [navbarColor, setNavbarColor] = useState("transparent");
  const [mobileMenu, setMobileMenu] = useState(false);
  const isDay = useSelector((state) => state.colorTheme.day);

  const handleThemeButton = () => {
    dispatch(changeColorTheme());
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navbarColor, isDay]);

  const changeNavbarColor = () => {
    if (window.scrollY >= windowDimensions.height) {
      // You can adjust the value based on when you want the color to change
      let color = isDay
        ? "bg-black bg-opacity-70"
        : "bg-[#4F51E7] bg-opacity-70";
      setNavbarColor(color);
    } else {
      setNavbarColor("transparent");
    }
  };

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMobileMenu = () => {
    // console.log("handleMobileMenu");
    setMobileMenu(!mobileMenu);
  };

  return (
    <header
      className={`${navbarColor} z-10 flex items-center py-4 w-full fixed transition-colors duration-300`}
      style={{
        // backgrounsdColor: navbarColor,
        backdropFilter: "blur(10px)",
      }}
    >
      {mobileMenu ? (
        <motion.div
          initial={{ width: 0, x: 1000 }}
          animate={{ width: "100%", x: 0 }}
          exit={{ width: 0, x: 1000 }}
          transition={{ duration: 0.5 }}
          className={`py-4 w-full h-screen -my-4 ${
            isDay ? "bg-black bg-opacity-50" : "bg-[#4F51E7] bg-opacity-50"
          }`}
        >
          <div className="flex justify-end px-4">
            <button
              className="block md:hidden bg-white p-2 rounded-md ml-4"
              onClick={handleMobileMenu}
            >
              <RxCross2 size={20} color="black" />
              {/* <RxHamburgerMenu color="black" size={20} /> */}
            </button>
          </div>
          <div>
            {/* <nav className="flex flex-col text-white text-lg font-semibold"> */}
            <ul className="py-16 grid grid-cols-1 gap-4 text-white font-semibold text-2xl">
              <li className={`hover:text-[#EFB433]`}>
                <a href="/">Home</a>
              </li>
              <li className="hover:text-[#EFB433]" onClick={handleMobileMenu}>
                <a href="#services">Services</a>
              </li>
              <li className="hover:text-[#EFB433]" onClick={handleMobileMenu}>
                <a href="#features">About</a>
              </li>
              <li className="hover:text-[#EFB433]" onClick={handleMobileMenu}>
                <a href="#technologies">Technologies</a>
              </li>
              {/* <li className="hover:text-[#EFB433]">
            <a href="/team">Team</a>
          </li> */}
              <li className="hover:text-[#EFB433]" onClick={handleMobileMenu}>
                <a href="#contact">Contact</a>
              </li>
            </ul>
            {/* </nav> */}
          </div>
        </motion.div>
      ) : (
        <div className="flex items-center justify-between px-4 md:px-0 md:justify-evenly w-full">
          <a href="/" className="flex items-center">
            <div className="bg-white rounded-full p-1">
              <img
                className="w-[40px] md:w-[50px]"
                src={Logo}
                alt="SAMS Infosoft Logo"
                width={40}
                style={{ aspectRatio: 1 }}
              />
            </div>
            <h1 className="md:block ml-2 font-bold text-xl md:text-xl lg:text-2xl text-white">
              SAMS INFOSOFT
            </h1>
          </a>
          <nav className="hidden md:flex text-white text-lg font-semibold">
            <ul className="grid grid-cols-5 gap-2">
              <li className={`hover:text-[#EFB433]`}>
                <a href="/">Home</a>
              </li>
              <li className="hover:text-[#EFB433]">
                <a href="#services">Services</a>
              </li>
              <li className="hover:text-[#EFB433]">
                <a href="#features">About</a>
              </li>
              <li className="hover:text-[#EFB433]">
                <a href="#technologies">Technologies</a>
              </li>
              {/* <li className="hover:text-[#EFB433]">
            <a href="/team">Team</a>
          </li> */}
              <li className="hover:text-[#EFB433]">
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </nav>
          <div className="flex items-center">
            <a href="/contact">
              <button
                type="button"
                className="hidden md:block text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br shadow-lg shadow-blue-500/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Get Quote
              </button>
            </a>
            {isDay ? (
              <button
                className="p-2 md:p-2.5 bg-white rounded-full ml-4"
                onClick={handleThemeButton}
              >
                <BsMoonStarsFill size={20} />
              </button>
            ) : (
              <button
                className="p-2 md:p-2.5 bg-white rounded-full ml-4"
                onClick={handleThemeButton}
              >
                <BsFillSunFill size={20} />
              </button>
            )}
            <button
              className="block md:hidden bg-white p-2 rounded-md ml-4"
              onClick={handleMobileMenu}
            >
              <RxHamburgerMenu color="black" size={20} />
            </button>
          </div>
        </div>
      )}
    </header>
  );
}

export default Navbar;
