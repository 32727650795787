import React from "react";
import { motion } from "framer-motion";
import WebDevelopment from "../../assets/icons/services/web-link.png";
import Andriod from "../../assets/icons/services/android.png";
import IOS from "../../assets/icons/services/apple.png";
import IOSWhite from "../../assets/icons/services/apple-white.png";
import ApplicationDesign from "../../assets/icons/services/service-design.svg";
import SEO from "../../assets/icons/services/service-seo.svg";
import CloudServer from "../../assets/icons/services/cloud-server.png";
import Automation from "../../assets/icons/services/onboarding.png";
import Hybrid from "../../assets/icons/services/cross-platform.png";
import Hosting from "../../assets/icons/services/cloud-computing.png";
import { useSelector } from "react-redux";
import { checkEven } from "../../helper";
function ServiceItems() {
  const isDay = useSelector((state) => state.colorTheme.day);

  const services = [
    {
      title: "Website Development",
      description:
        "Stunning, fast, and user-friendly websites that captivate users and boost your business",
      icon: WebDevelopment,
    },
    {
      title: "Application Design",
      description:
        "Beautiful, intuitive apps that simplify daily tasks and enhance user experience",
      icon: ApplicationDesign,
    },
    {
      title: "Android Development",
      description:
        "Reliable, feature-rich Android apps tailored to your audience’s needs",
      icon: Andriod,
    },
    {
      title: "IOS Development",
      description:
        "Secure, well-designed iOS apps that meet Apple’s standards for a seamless experience",
      icon: isDay ? IOS : IOSWhite,
    },
    {
      title: "Hybrid Development",
      description:
        "Cost-effective hybrid apps that work perfectly across all platforms",
      icon: Hybrid,
    },
    {
      title: "Web Hosting",
      description:
        "Reliable, secure hosting to keep your website running smoothly 24/7",
      icon: Hosting,
    },
    {
      title: "SEO Optimization",
      description:
        "Proven strategies to boost your website’s visibility, traffic, and search rankings",
      icon: SEO,
    },
    {
      title: "Cloud Server",
      description:
        "Scalable, secure cloud solutions for data safety and accessibility",
      icon: CloudServer,
    },
    {
      title: "Automations",
      description:
        "Streamline repetitive tasks to increase efficiency and focus on core goals",
      icon: Automation,
    },
  ];
  return (
    <div className="z-0 w-full p-4 grid grid-cols-1 md:px-12 md:grid-cols-3 gap-8">
      {services.map((service, index) => {
        return (
          <motion.div
            key={index}
            initial={{
              x: checkEven(index + 1) ? -500 : 500,
            }}
            animate={{
              x: 0,
            }}
            transition={{ duration: 0.5, delay: 0.5 * (index + 1) }}
            className={`z-0 shadow-md rounded-md ${
              isDay ? "shadow-gray-300" : "shadow-[#4F51E7]"
            }`}
          >
            <div
              className={`hover:scale-110 duration-200 z-0 w-full h-full ${
                isDay ? "bg-white" : "bg-black"
              } flex justify-center flex-col items-center p-8 rounded-md shadow-inner ${
                isDay ? "shadow-gray-300" : "shadow-[#4F51E7]"
              }`}
            >
              <img
                className="z-0"
                src={service.icon}
                alt={service.title}
                width={50}
                style={{ aspectRatio: 1 }}
              />
              <h1 className="z-0 text-2xl mt-4">{service.title}</h1>
              <p className={`z-0 ${isDay ? "text-black" : "text-white"} mt-2`}>
                {service.description}
              </p>
            </div>
          </motion.div>
        );
      })}
    </div>
  );
}

export default ServiceItems;
